import React from 'react';
import { Block } from '@bgea-js/react-ui';

const privacyPolicyCopyId = () => (
  <div>
    <Block textSize={6}>
      Dengan melengkapi data pribadi (termasuk pandangan rohani Anda), Anda
      setuju untuk menggunakan data Anda untuk berhubungan mengenai
      kegiatan-kegiatan dan sebagai sarana untuk memberitahukan kegiatan
      pelayanan BGEA lainnya; dan menggunakan data tersebut untuk mengelola
      keterlibatan Anda dengan BGEA. Data-data tersebut akan di simpan oleh BGEA
      di Amerika dan/atau di negara dimana data terkumpulkan (dan dapat
      diteruskan kepada mitra pelayanan kami di negara Anda untuk diproses)
      hanya untuk keperluan-keperluan yang tertera di atas. Untuk memahami dan
      mengetahui lebih banyak tentang bagaimana kami mengolah data-data Anda,
      Anda dapat melihat kebijakan privasi BGEA di 
      <a href="https://billygraham.org/id/kebijakan-privasi/" target="_blank" rel="noreferrer"> BillyGraham.org/kebijakan-privasi</a>.
    </Block>
  </div>
);

export default privacyPolicyCopyId;
