import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><section {...{
        "id": "application"
      }}>{`
  `}<div parentName="section" {...{
          "className": "main-container"
        }}>{`
  `}<div parentName="div" {...{
            "className": "margins"
          }}>{`
    `}<div parentName="div">{`
      `}<h1 parentName="div">{`APPLICATIONS ARE `}<strong parentName="h1">{`CLOSED`}</strong></h1>{`
    `}</div>{`
    `}<div parentName="div">{`
      `}<p parentName="div" {...{
                "className": "mt-5"
              }}>{`Applications to the European Congress on Evangelism are now closed. If you applied to attend, we will process your application as soon as we are able.`}</p>{`
      `}<a parentName="div" {...{
                "href": "https://events.billygraham.org/event/berlinapplication",
                "target": "_blank",
                "className": "button mt-2 mb-5 is-hidden"
              }}>{`Apply Now`}</a>{`
      `}<p parentName="div"><a parentName="p" {...{
                  "href": "mailto:contact.berlincongress@billygraham.org",
                  "className": "link"
                }}>{`Questions? Contact us here.`}</a></p>{`
    `}</div>{`
  `}</div>{`
  `}</div>
      </section>
      <section {...{
        "id": "quote"
      }}>{`
  `}<div parentName="section" {...{
          "className": "quote-wrapper"
        }}>{`
      `}<div parentName="div" {...{
            "className": "main-container"
          }}>{`
        `}<div parentName="div" {...{
              "className": "margins"
            }}>{`
        `}<div parentName="div" {...{
                "className": "quote-container-1"
              }}>{`
          `}<div parentName="div">{`
            `}<p parentName="div" {...{
                    "className": "quote"
                  }}><strong parentName="p">{`“I AM NOT ASHAMED OF THE GOSPEL`}</strong>{`, FOR IT IS THE POWER OF GOD FOR SALVATION TO EVERYONE WHO BELIEVES …”`}</p>{`
            `}<p parentName="div" {...{
                    "className": "cite mb-5"
                  }}>{`—ROMANS 1:16, ESV`}</p>{`
          `}</div>{`
          `}<div parentName="div">{`
            `}<p parentName="div" {...{
                    "className": "caption"
                  }}>{`1966 Berlin, Germany, was home to the first European Congress on Evangelism.`}</p>{`
          `}</div>{`
        `}</div>{`
        `}<div parentName="div" {...{
                "className": "quote-container-2"
              }}>{` `}</div>{`
      `}</div>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="section" {...{
          "id": "berlin-mobile"
        }}>{`
    `}<img parentName="div" {...{
            "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/verse-background-mobile-1.jpg",
            "alt": "Berlin"
          }}></img>{`
  `}</div>
      </section>
      <section {...{
        "id": "history"
      }}>{`
  `}<div parentName="section" {...{
          "className": "main-container"
        }}>{`
  `}<div parentName="div" {...{
            "className": "margins"
          }}>{`
    `}<div parentName="div">{`
      `}<h2 parentName="div">{`SEEKING TO REIGNITE, UNIFY, AND STRENGTHEN`}</h2>{`
      `}<h1 parentName="div">{`The church in `}<strong parentName="h1">{`Europe`}</strong></h1>{`
      `}<img parentName="div" {...{
                "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/the-church-in-europe.jpg",
                "alt": "Crowd attending conference"
              }}></img>{`
    `}</div>{`
    `}<div parentName="div">{`
      `}<p parentName="div">{`In May of 2025, the Billy Graham Evangelistic Association (BGEA) is hosting the European Congress on Evangelism in Berlin—25 years after the Amsterdam 2000 conference prepared thousands of ministry leaders to `}<em parentName="p">{`“testify to the gospel of the grace of God”`}</em>{` (Acts 20:24, ESV).`}</p>{`
      `}<p parentName="div">{`While much has changed in Europe since the conference in Amsterdam, the condition of the human heart hasn’t changed. More than ever, Franklin Graham, BGEA’s president and CEO, believes there is an urgent and critical need for churches to share the hope and truth of God’s Word in their communities—the only message that has the power to change lives.`}</p>{`
      `}<p parentName="div">{`Church leaders from 57 countries and territories in Europe are invited to attend, and this conference is expected to be the most representative gathering of Christian leaders in Europe since Amsterdam 2000. For three days and in eight languages, well-known Christian speakers will focus on the theme of Romans 1:16—being unashamed of the Gospel of Christ—to encourage believers to reignite the church with a passion for proclamation evangelism.`}</p>{`
      `}<p parentName="div">{`“The church in Europe needs to rise up with fresh confidence. The European Congress on Evangelism will provide the inspiration, strength, and confidence in the Gospel that will enable us to do this,” said Dr. Hugh Osgood, president of the UK-based ministry Churches in Communities International. “Let’s prioritize this opportunity to be together to advance God’s kingdom.”`}</p>{`
    `}</div>{`
  `}</div>{`
  `}</div>
      </section>
      <section {...{
        "id": "president-photo"
      }}>{`
  `}<div parentName="section" {...{
          "className": "main-container"
        }}>{`
    `}<div parentName="div" {...{
            "className": "margins"
          }}>{`
      `}<div parentName="div" {...{
              "className": "president-quote"
            }}>{`
        `}<p parentName="div" {...{
                "className": "president-quote-p"
              }}>{`“Proclaiming the Good News of Jesus Christ is still the greatest need in the world today, and it’s an honor for BGEA to once again come alongside and support the believers in Europe who are doing this important work.”`}</p>{`
        `}<p parentName="div" {...{
                "className": "quote-title"
              }}>{`
          —FRANKLIN GRAHAM
        `}</p>{`
        `}<span parentName="div" {...{
                "className": "presidet-ceo"
              }}>{`
          PRESIDENT AND CEO  
        `}</span><br parentName="div"></br>{`
        `}<span parentName="div" {...{
                "className": "presidet-company"
              }}>{`
          Billy Graham Evangelistic Association
        `}</span>{`
        `}<br parentName="div"></br>{`
         `}<p parentName="div" {...{
                "className": "presidet-ceo"
              }}>{`
        `}<span parentName="p">{`
            CONVENER 
        `}</span><br parentName="p"></br>{`
        `}<span parentName="p" {...{
                  "className": "presidet-company"
                }}>{`
            European Congress on Evangelism
          `}</span>{`
        `}</p>{`
      `}</div>{`
      `}<div parentName="div" {...{
              "className": "president-image"
            }}>{`
        `}<img parentName="div" {...{
                "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/franklin-graham.jpg",
                "alt": "Franklin Graham"
              }}></img>{`
      `}</div>{`
    `}</div>{`
  `}</div>
      </section>
      <section {...{
        "id": "video"
      }}>{`
  `}<div parentName="section" {...{
          "className": "main-container"
        }}>{`
    `}<div parentName="div" {...{
            "className": "margins"
          }}>{`
      `}<div parentName="div" {...{
              "className": "flex-video"
            }}>{`
        `}<iframe parentName="div" {...{
                "className": "responsive-iframe",
                "type": "text/javascript",
                "src": "https://cdnapisec.kaltura.com/p/650742/sp/65074200/embedIframeJs/uiconf_id/49982243/partner_id/650742?iframeembed=true&playerId=kaltura_player_1694532980&entry_id=1_5pl6ti6m",
                "style": {
                  "width": "100%",
                  "height": "100%"
                },
                "allowFullScreen": true,
                "webkitallowfullscreen": "",
                "mozallowfullscreen": "",
                "allow": "autoplay *; fullscreen *; encrypted-media *",
                "frameBorder": "0"
              }}></iframe>{`
      `}</div>{`
      `}<div parentName="div" {...{
              "id": "video-quote"
            }}>{`
        `}<p parentName="div" {...{
                "className": "quote"
              }}>{`“In our challenging times, Christians and churches must join hands for spreading the Good News of Jesus Christ all over Europe again. I am looking forward to the upcoming European Congress on Evangelism and trust it will trigger enthusiasm and a fresh commitment to do so.”`}</p>{`
        `}<p parentName="div" {...{
                "className": "cite"
              }}>{`—ULRICH PARZANY, GERMANY`}</p>{`
      `}</div>{`
    `}</div>{`
  `}</div>
      </section></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      