import React, { useState, useEffect } from 'react';
import './EuroHeader.scss'

function EuroHeader() {
//navbar scroll when active state
const [navbar, setNavbar] = useState(false);

//navbar scroll changeBackground function
const changeBackground = () => {
    console.log(window.scrollY);
    if (window.scrollY >= 500) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground);
  });

  return (
    <div className="header">
      <div className="logo-wrapper">
        <div>
          <img src="https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/oct/ecoe-logo-20241029.png" alt="European Congress on Evangelism Berlin 2025 27 through 30 May" id="logo" />
        </div>
        <div>
          <img src="https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/header-map-full-dot.png" alt="Europe map" id="map" />
        </div>
      </div>
      <nav className={navbar ? 'header-container-active' : 'header-container'} id="mobile-nav">
        <div className='burger-container'>
          <label for="burger-check" class="burger"></label>
          <a className="button is-hidden" id="burger-apply" href="https://events.billygraham.org/event/berlinapplication" target="_blank" rel="noreferrer">Apply Now</a>
        </div>
        <input class="burger-check" id="burger-check" type="checkbox" />
        <div className='navigation'>
          <a href="#history">History</a>
          <a href="#speakers">Presenters</a>
          <a href="#schedule">Schedule</a>
          <a className="button is-hidden" href="https://events.billygraham.org/event/berlinapplication" target="_blank" rel="noreferrer">Apply Now</a>
        </div>
      </nav>
      <div id="desktop-nav">
        <div className='navigation'>
            <a href="#history">History</a>
            <a href="#speakers">Presenters</a>
            <a href="#schedule">Schedule</a>
            <a className="button is-hidden" href="https://events.billygraham.org/event/berlinapplication" target="_blank" rel="noreferrer">Apply Now</a>
        </div>
      </div>
  </div>
  )
}


export default EuroHeader;