import React from 'react';
import { Block, Columns, Container, Content } from '@bgea-js/react-ui';

const customConfirmationEnSHIC = () => (
  <div>
    <Columns>
      <Container>
        <Content>
          <Block>
            <a href="https://learning.billygraham.org">
              https://learning.billygraham.org
            </a>
          </Block>
          <Block>
            Thank you for registering for the free{' '}
            <em>Sharing Hope in Crisis On-Demand Training Seminar</em>. You will
            receive an email with instructions on how to access the course.
          </Block>
          <Block>
            We look forward to your participation as you learn ways to share
            God’s compassion and hope with those who have been affected by a
            crisis.
          </Block>
          <Block>
            The training you will receive is designed to help you minister
            effectively to those in crisis, as you share the compassion and love
            of Jesus Christ,{' '}
            <em>
              “who comforts us in all our affliction, so that we may be able to
              comfort those who are in any affliction, with the comfort with
              which we ourselves are comforted by God”
            </em>{' '}
            (2 Corinthians 1:4).
          </Block>
          <Block>
            If you have any questions, please email us at{' '}
            <a href="mailto:rrt@bgea.org">rrt@bgea.org</a> and reference SHIC
            On-Demand in the subject line.
          </Block>
        </Content>
      </Container>
    </Columns>
  </div>
);

export default customConfirmationEnSHIC;
