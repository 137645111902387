import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<p parentName="div">{`Dear Friend,`}</p>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     I have just returned from historic Gospel outreaches in Southeast
    Asia. We give God all the glory for the thousands of lives He changed for
    eternity—and I thank Him for the opportunity to boldly proclaim His truth
    that “`}<em parentName="div">{`Christ was raised from the dead by the glory of the Father`}</em>{`”
    (Romans 6:4, NKJV).
    `}<strong parentName="div">{`
      It was such an honor to be invited to share God’s love in two
      countries—Vietnam and Laos—where such events would have been hard to
      imagine just a few years ago.
    `}</strong>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     Our first stop was Vietnam for the Season of Love Festival. God had
    opened the door
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
      `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHYSmlpCEv/xAAbEAACAgMBAAAAAAAAAAAAAAABAwACBBIiIf/aAAgBAQABBQJmRoA8QMvQN9hHKO6f/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEh/9oACAEDAQE/AV1//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHRAAAgIBBQAAAAAAAAAAAAAAABEBEgIDUWFigv/aAAgBAQAGPwLmrMe8CqzwaUk23R//xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhQWEx/9oACAEBAAE/IfWwCNq1l8FFIfc4XpyoYRuyabkNrgf/2gAMAwEAAgADAAAAEBfP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPxBGlZ//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQIBAT8QUtML/8QAHBABAQADAQADAAAAAAAAAAAAAREAIUExcZHh/9oACAEBAAE/EAMIhsSqz9xOAgnqe/eFCbR125844+BaOXXcjar2UAOHMqjOEjQEz//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "fg vnam bible gift all people",
              "title": "fg vnam bible gift all people",
              "src": "/static/d2687e741af027fc0fb5f080302c4b2b/6a068/fg_vnam_bible_gift_all_people.jpg",
              "srcSet": ["/static/d2687e741af027fc0fb5f080302c4b2b/f93b5/fg_vnam_bible_gift_all_people.jpg 300w", "/static/d2687e741af027fc0fb5f080302c4b2b/b4294/fg_vnam_bible_gift_all_people.jpg 600w", "/static/d2687e741af027fc0fb5f080302c4b2b/6a068/fg_vnam_bible_gift_all_people.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
      `}<figcaption parentName="div" {...{
            "className": "category"
          }}>{`
        `}<strong parentName="figcaption">{`AT GOVERNMENT OFFICES IN HANOI,`}</strong>{` Deputy Prime Minister
        Nguyen Hoa Binh presented Franklin Graham with a Bible that had been
        translated into one of Vietnam’s tribal languages.
      `}</figcaption>{`
    `}</div>{`
    for me to preach the Good News there in Hanoi in 2017 and again in Ho Chi
    Minh City in 2023, and it was a blessing to return. Before the Festival, I
    was welcomed by Vietnam’s Deputy Prime Minister Nguyen Hoa Binh and a
    distinguished delegation that included the Ministry of Foreign Affairs and
    the Government Committee for Religious Affairs at their offices in Hanoi.
    They presented me with a remarkable gift—a copy of God’s Word in one of
    Vietnam’s tribal languages.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     
    `}<strong parentName="div">{`
      On the first night of Season of Love, nearly 14,000 people packed into Can
      Tho Stadium`}</strong>{`, Vietnam’s second-largest soccer venue.
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      