import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="content-wrapper featured" id="pre-events">
    <div className="margins">
        <div className="content-container english">
            <h1 className="bottom-title"><span>ПРЕДСТОЯЩИЕ ЗАПЛАНИРОВАННЫЕ</span></h1>
            <h1 className="top-title">МЕРОПРИЯТИЯ</h1>
        </div>
        <div className="content-container" id="pre-events-list">
            <div className="margins">
                <div className="event column">
                    <p className="event-entry-day">Суб.</p>
                    <div className="pre-event-content-container">
                        <p className="date">25 января 2025</p>
                        <h2>СТАРТ «ВРЕМЕНИ НАДЕЖДЫ»</h2>
                    </div>
                    <div className="pre-event-content-container">
                        <div className="preview-container">
                            <div>
                                <p>Церковь Олевисте</p>
                                <p>Лай 50</p>
                                <p>10133 Таллинн, Эстония</p>
                            </div>
                            <a id="details2" className="modal-btn" href="#modal2">&#8250;</a>
                        </div>
                        <section id="modal2" className="modal-dialog">
                            <div className="modal-overlay"></div>
                            <div role="dialog" className="modal-content">
                                <a title="Close modal" href="#details2" className="modal-close"><span className="closeModal">close</span></a>
                                <h1>Запуск «Времени Надежды»</h1>
                                <p className="time">25 января, 16:00-18:00</p>
                                <div className="location-block">
                                    <p className="location">Церковь Олевисте</p>
                                    <p className="location">Лай 50, 10133 Таллинн, Эстония</p>
                                </div>
                                <p className="details">Мы рады пригласить вас и всю вашу общину на торжественное богослужение в церкви Олевисте, посвященное старту подготовки к фестивалю «Время надежды». Мы будем славить Господа вместе с хором «Экклесия», послушаем слово от евангелиста Виктора Гамма, вице-президента ЕАБГ, будем молиться об Эстонии и подготовке к фестивалю. После собрания — неформальное общение и кофе.</p>
                                <p className="details">Пожалуйста, сообщите о вашем участии и количестве гостей к 15 января, позвонив по телефону +372 57877748 или написав на адрес электронной почты <a className="link" href="mailto: info@lootuseaeg.com">info@lootuseaeg.com</a>. 
                                </p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      