import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { NewNames as NewNamesForm } from "@bgea-js/form-templates";
import customConfirmationDecisionMagazine from "./customConfirmationDigitalcopy";
import './pray-president.scss';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className="form-container">
    <NewNamesForm esubCodes={['BGEA', 'DMAGNL']} country={['US']} campaignCodes={[{
        key: "SPECIAL",
        value: "DPFP25"
      }]} SESourceCode={'BD251LPFP'} donateProp={false} mailBox={false} collectionType={489} donationCollectionType={1} productTitle={"NN Decision Magazine Soft Copy"} productId={""} buttonText={"SEND MY FREE ISSUE"} emailTemplateCampaign={"NN Decision Magazine Soft Copy"} hideDonationForm={true} isReq={true} isChecked={true} recurringToggle={false} donationDateButtons={false} productTitle={"Decision Digital Election Guide 2024"} resourceName={"The special election Decision magazine"} showCustomHeader={false} showNameLabel={false} nbLatency={600} backgroundColor={'#f8f8f8'} customConfirmation={customConfirmationDecisionMagazine} privacyPolicyCopy={"By providing your personal details, you accept that we will use your personal details to communicate with you about ministry updates and opportunities from Billy Graham Evangelistic Association.  <a href='https://billygraham.org/privacy-policy/' target='_blank'><u>View our full Privacy Policy</u>. </a>"} mdxType="NewNamesForm" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      