import { GetInvolved } from '@bgea-js/form-templates';
import "../../../../src/content/medan-get-involved/medan.scss";
import medanConfirmation from "../../../../src/content/medan-get-involved/medanConfirmation";
import privacyPolicyCopyId from "../../../../src/content/medan-get-involved/privacyPolicyId";
import * as React from 'react';
export default {
  GetInvolved,
  medanConfirmation,
  privacyPolicyCopyId,
  React
};