import React from 'react';

const medanConfirmation = () => (
  <div>
    <h1 className="title">Terima kasih!</h1>
    <div className="block">
      <p>Pengajuan Anda telah selesai.</p>
    </div>
  </div>
);

export default medanConfirmation;
