import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import MainContentJan from "../../../../src/content/prayer-letter-january/main-content-jan.md";
import LearnMoreJan from "../../../../src/content/prayer-letter-january/learn-more-details-jan.md";
import "../../../../src/content/prayer-letter-january/prayer-letter-january.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationForm,
  MainContentJan,
  LearnMoreJan,
  React
};