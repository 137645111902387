import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="content-wrapper featured" id="pre-events">
    <div className="margins">
        <div className="content-container english">
            <h1 className="bottom-title"><span>OTHER UPCOMING</span></h1>
            <h1 className="top-title">EVENTS</h1>
        </div>
        <div className="content-container" id="pre-events-list">
            <div className="margins">
                <div className="event column">
                    <p className="event-entry-day">SAT</p>
                    <div className="pre-event-content-container">
                        <p className="date">January 25, 2025</p>
                        <h2>Time of Hope Launch</h2>
                    </div>
                    <div className="pre-event-content-container">
                        <div className="preview-container">
                            <div>
                                <p>Oleviste Church</p>
                                <p>Lai tn 50</p>
                                <p>10133 Tallinn, Estonia</p>
                            </div>
                            <a id="details2" className="modal-btn" href="#modal2">&#8250;</a>
                        </div>
                        <section id="modal2" className="modal-dialog">
                            <div className="modal-overlay"></div>
                            <div role="dialog" className="modal-content">
                                <a title="Close modal" href="#details2" className="modal-close"><span className="closeModal">close</span></a>
                                <h1>Time of Hope Launch</h1>
                                <p className="time">January 25, 4:00 P.M. - 6:00 P.M.</p>
                                <div className="location-block">
                                    <p className="location">Oleviste Church</p>
                                    <p className="location">Lai tn 50, 10133 Tallinn, Estonia</p>
                                </div>
                                <p className="details">We are pleased to invite you and your entire congregation to a special worship service at Oleviste Church to mark the official launch of preparations for the Time of Hope Festival. Together, we will worship the Lord with the “Ekklesia” choir; listen to a message from Viktor Hamm, vice president of Crusade Ministries for BGEA; and pray for Estonia and for the Festival’s preparations. Following the service, there will be time for informal fellowship and coffee.</p>
                                <p className="details">Please let us know if you will attend, and how many guests will accompany you, by Jan. 15. You may call +372 57877748 or email <a className="link" href="mailto:info@lootuseaeg.com">info@lootuseaeg.com</a>.
                                </p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      