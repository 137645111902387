import DigitalHardFormDropdown from "../../../../src/components/digital-hard-form-dropdown.js";
import HardCopyForm from "../../../../src/content/pray-for-president/form-hard-copy.mdx";
import DigitalForm from "../../../../src/content/pray-for-president/form-digital.mdx";
import "../../../../src/content/pray-for-president/pray-president.scss";
import * as React from 'react';
export default {
  DigitalHardFormDropdown,
  HardCopyForm,
  DigitalForm,
  React
};