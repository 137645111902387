import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { GetInvolved as GetInvolvedForm } from '@bgea-js/form-templates';
import eeEstoniaPrivacyPolicyCopy from './eeEstoniaPrivacyPolicyCopy';
import eeEstoniaConfirmation from './eeEstoniaConfirmation';
import eeEstoniaIAACopy from './eeEstoniaIAACopy';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className="content-wrapper" id="eeGetInvolvedCopy">
    <div className="margins">
        <div className="content-container">
            <h1 className="top-title">OSALE!</h1>
        </div>
        <div className="text content-container">
            <p>Festivali kordaminekuks vajame vabatahtlikke abilisi, kes on valmis enne festivali ja festivali ajal kaasa teenima: vahendades infot ja aidates festivali külastajaid erinevates valdkondades.</p>
        </div>
    </div>
    </div>
    <div className="content-wrapper" id="eeGetInvolvedForm">
    <div className="margins">
        <div id="get-involved-form">
            <GetInvolvedForm churchAddressVisibleList={['street', 'stateProvince', 'cityTownRegion', 'zipPostalCode']} churchPhoneType="BUS" collectionType={477} country={['EE']} destination={3} disclaimerOptInText="Annate sellega nõusoleku ühenduse hoidmiseks ja info jagamiseks seoses festivaliga „Lootuse aeg“. Võite sellest igal ajal loobuda." displayList={['Email', 'Phone', 'SocialPhones', 'DisclaimerOptInText']} emailId="" emailRequired={true} eventId={"62"} formAttribute="1574" hidePrayerHatOffer={true} hideUserAddressSection iaaAttrValue="1580" languageCode="DEFLANG" lng="et" optInAttrValue="2100" optInAttribute="2100" orgRoles={{
            '': 'Valige Üks',
            'CSP': 'Pastor',
            'CYP': 'Noortetöö juht',
            'CHUR/MEM': 'Liige'
          }} nameTitles={{
            '': 'Valige Üks',
            'Dr.': 'Dr.',
            'Mr.': 'Hr.',
            'Mrs.': 'Pr.',
            'Ms.': 'Prl.',
            'Pastor': 'Pastor',
            'Reverend': 'Reverend'
          }} phoneRequired={false} phoneType="CELL" requiredChurchList={['OrganizationName', 'OrganizationRole', 'Address']} showAndrewSection={true} showPrefix={false} socialPhones={[{
            isRequired: false,
            label: 'WhatsApp',
            type: 'WHATSAPP'
          }]} useMobilizationAddress={true} useOptinSocialPhoneFields={true} volunteerAttrValue="1232" customConfirmation={eeEstoniaConfirmation} iaaCopy={eeEstoniaIAACopy} privacyPolicyCopy={eeEstoniaPrivacyPolicyCopy} text="Saada" logicIds={['CellToMobile']} mdxType="GetInvolvedForm" />
        </div>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      