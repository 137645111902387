import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<div parentName="div">{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABzaTVFRTL/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxESMf/aAAgBAQABBQKtXeGt86isVAc6ff/EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAEDAQE/AYf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAACAwEAAAAAAAAAAAAAAAAAIQEQETL/2gAIAQEABj8CRL3DmBX/AP/EABoQAAMBAQEBAAAAAAAAAAAAAAABETEhgZH/2gAIAQEAAT8hZxp6IsSprpa5T6QAV3otY//aAAwDAQACAAMAAAAQiw//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QNj//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCn/8QAHBABAQEAAQUAAAAAAAAAAAAAAREAIUFRkcHR/9oACAEBAAE/EI/dygmSrsIVvvHmgv1rJlK5s8wxNXWPXf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Couples praying in Alaska",
            "title": "Couples praying in Alaska",
            "src": "/static/8c209860ccb1bc730147ecb85a433d97/6a068/241201_dj_0044.jpg",
            "srcSet": ["/static/8c209860ccb1bc730147ecb85a433d97/f93b5/241201_dj_0044.jpg 300w", "/static/8c209860ccb1bc730147ecb85a433d97/b4294/241201_dj_0044.jpg 600w", "/static/8c209860ccb1bc730147ecb85a433d97/6a068/241201_dj_0044.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div">{`
      `}<strong parentName="figcaption">{`SOME 13,800 PEOPLE `}</strong>{`came to Can Tho Stadium to hear the
      Good News at the first night of the Season of Love Festival in Southern
      Vietnam Dec. 3–4.
    `}</figcaption>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
    I began the service on the first night with prayer for Vietnam’s government
    officials, as the Bible instructs all Christians to pray for those in
    authority. Then, I shared a clear presentation of the Gospel—and the Holy
    Spirit worked mightily. Hoa*—one of our prayer counselors—was standing at
    the front when people began to walk forward at the invitation. She had the
    privilege of watching as three of her friends surrendered their lives to the
    Lord.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     “I would love to help many people believe in Christ. So I invited my
    friends to come here, and seeing that they came up and accepted Christ into
    their lives, I feel so happy,” Hoa said.
    `}<strong parentName="div">{`
      “I cannot express it in words. I’m so thankful to God for this Festival`}</strong>{`, and for those who made this happen.”
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     We give God all the glory for the harvest we saw. Cadeo,* another
    Vietnamese believer, is celebrating following the Festival too. The
    17-year-old also served as a prayer counselor and had
    `}<div parentName="div" {...{
          "className": "image-float-left"
        }}>{`
      `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAEbDtHl2Tf/xAAdEAACAQQDAAAAAAAAAAAAAAABAgMABBESEyEz/9oACAEBAAEFAkQueLUtGim2bFDt5/X/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGn/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABkQAQEAAwEAAAAAAAAAAAAAAAEAAiFBEf/aAAgBAQAGPwJOE4vS8W123ub/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMWGh/9oACAEBAAE/Ia3L8o4IGk7ZR+RY7GsPDHeZnk//2gAMAwEAAgADAAAAELzP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERMf/aAAgBAwEBPxDThT//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QQGwX/8QAGxABAAIDAQEAAAAAAAAAAAAAAREhADFBUcH/2gAIAQEAAT8QLCUVL4o93iBgU+6qMkejdPOcrClA6YWRN4mtRi7gv7jeZGnmf//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "vnam nt 1 counsel",
              "title": "vnam nt 1 counsel",
              "src": "/static/0c6c89a86c393c0062c519f27e6545eb/6a068/vnam_nt_1_counsel.jpg",
              "srcSet": ["/static/0c6c89a86c393c0062c519f27e6545eb/f93b5/vnam_nt_1_counsel.jpg 300w", "/static/0c6c89a86c393c0062c519f27e6545eb/b4294/vnam_nt_1_counsel.jpg 600w", "/static/0c6c89a86c393c0062c519f27e6545eb/6a068/vnam_nt_1_counsel.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}</div>{`
    invited two of his friends to church—but they never came. When he asked them
    to come to the Festival, they agreed.
    `}<strong parentName="div">{`
      The teens listened closely and then responded to Jesus Christ’s call:
    `}</strong>{`
    “`}<em parentName="div">{`If anyone desires to come after Me, let him deny himself, and take up his
      cross daily, and follow Me`}</em>{`” (Luke 9:23, NKJV).
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     “They told me that before they had God, they felt like their lives had
    no goals,” Cadeo told us through a translator. Now, he explained, “They are
    going to work for God. … They are very happy because they have God in their
    lives.”
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABnbycqeTB/8QAGhABAQACAwAAAAAAAAAAAAAAAQIAAxAREv/aAAgBAQABBQKKUKzZXqykzt4//8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEREv/aAAgBAwEBPwGmZf/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/Aar/xAAZEAEAAgMAAAAAAAAAAAAAAAABACAxQpH/2gAIAQEABj8CMTXkVp//xAAbEAADAAIDAAAAAAAAAAAAAAAAAREhMUFhkf/aAAgBAQABPyGpe0MSxouBdFpkk4dxLs//2gAMAwEAAgADAAAAEOjP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAREQ/9oACAEDAQE/EF2Zf//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/ECf/xAAaEAEBAQADAQAAAAAAAAAAAAABEQAhUWFB/9oACAEBAAE/EAksCLdU91QFyUiPfOYGURJJPMhek+cGKX1mlSve/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "laos crowd ky 241202",
            "title": "laos crowd ky 241202",
            "src": "/static/d337a75e60591a711d4f8d7defc45cfd/6a068/laos_crowd_ky_241202.jpg",
            "srcSet": ["/static/d337a75e60591a711d4f8d7defc45cfd/f93b5/laos_crowd_ky_241202.jpg 300w", "/static/d337a75e60591a711d4f8d7defc45cfd/b4294/laos_crowd_ky_241202.jpg 600w", "/static/d337a75e60591a711d4f8d7defc45cfd/6a068/laos_crowd_ky_241202.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div">{`
      `}<strong parentName="figcaption">{`THOUSANDS HEARD THE GOOD NEWS `}</strong>{`of Jesus Christ during the
      historic Christmas of Love Festival in Laos.
    `}</figcaption>{`
  `}</div>{`
  `}<h4 parentName="div">{`LAOS`}</h4>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     From there, I traveled to Laos, where
    `}<strong parentName="div">{`
      I had the chance to share a message from God’s Word with nearly 14,000
      men, women, and children in Vientiane—the capital of Laos`}</strong>{`—during the Christmas of Love Festival. Once again, I opened the evening
    with prayer for the country’s leadership. We enjoyed powerful worship, which
    was led by Dennis Agajanian, Charity Gayle, the Tommy Coomes Band, and the
    Lao Evangelical Church worship team. Then I explained the true meaning
    behind Christmas: “`}<em parentName="div">{`In this is love, not that we loved God, but that He loved us and sent His
      Son to be the propitiation for our sins`}</em>{`” (1 John 4:10, NKJV).
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
      `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAEsITdDJ//EABsQAAIDAAMAAAAAAAAAAAAAAAIDABETARIi/9oACAEBAAEFAs6nGvQhZbG+muotCn//xAAYEQACAwAAAAAAAAAAAAAAAAAAAQIREv/aAAgBAwEBPwGTo2z/xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQIBAT8BjL//xAAbEAADAAIDAAAAAAAAAAAAAAAAAQIRMRBBYf/aAAgBAQAGPwJXXSF7w5xpjxKNn//EABsQAAIDAAMAAAAAAAAAAAAAAAERACExQVGB/9oACAEBAAE/IeFLFbCYADPrIYBAdapg+EoeLlhDeGf/2gAMAwEAAgADAAAAEOfP/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEhQVH/2gAIAQMBAT8QZJ2LQ//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEVH/2gAIAQIBAT8QC2OX/8QAHRABAQADAAIDAAAAAAAAAAAAAREAIUExUWFx0f/aAAgBAQABPxBAQrK2UpJ8pgjBFnj/AHExmOFxNQCKOkGGvrWR/Ra89esAg4nA8Dn/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "241202  ps10184",
              "title": "241202  ps10184",
              "src": "/static/477f27e26cd1e199c58a7b9a24d787fa/6a068/241202__ps10184.jpg",
              "srcSet": ["/static/477f27e26cd1e199c58a7b9a24d787fa/f93b5/241202__ps10184.jpg 300w", "/static/477f27e26cd1e199c58a7b9a24d787fa/b4294/241202__ps10184.jpg 600w", "/static/477f27e26cd1e199c58a7b9a24d787fa/6a068/241202__ps10184.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
      `}<figcaption parentName="div">{`
        `}<strong parentName="figcaption">{`NEARLY 14,000 PEOPLE `}</strong>{`heard the Good News in Laos—where
        Franklin Graham shared a message about the love of God during a two-day
        evangelistic outreach over the weekend.
      `}</figcaption>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     The Holy Spirit worked in many hearts, including those of Mali* and
    her two young daughters, 11-year-old Sunya* and 6-year-old Ying.* All three
    of them prayed in repentance and faith to receive Jesus Christ. “Many people
    have told me about the Gospel so that’s why I came,” Mali shared with one of
    our team members. A believer from a church near her home had invited the
    family to the Festival. “`}<strong parentName="div">{`I know that Jesus Christ is God. I just believed!`}</strong>{`” Before their
    conversation ended, Mali said she planned to share the Gospel with her
    husband, who is not a Christian and had stayed home.
    `}<br parentName="div"></br><br parentName="div"></br>{`
     `}<div parentName="div" {...{
          "className": "image-float-left"
        }}>{`
      `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "64.33333333333333%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAB/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgH/2gAMAwEAAhADEAAAAcFiN2KZ/8QAGhABAAIDAQAAAAAAAAAAAAAAAgEDABETIf/aAAgBAQABBQKKwiQZzVGNbFbnmqvf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERMf/aAAgBAwEBPwHZW//EABgRAAIDAAAAAAAAAAAAAAAAAAABAhEh/9oACAECAQE/AY6ij//EAB8QAAIBAgcAAAAAAAAAAAAAAAABAhARITEyM0FRkf/aAAgBAQAGPwKTeD4NPhuWJPqmbP/EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQVFh/9oACAEBAAE/IQNspUZBxxcVBn4qLQh46iWDRuQVsv/aAAwDAQACAAMAAAAQax//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8Q0ULV/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxDC2Qf/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUFRgdH/2gAIAQEAAT8QMpZLSuGqx2WKkOjk0W+kz2VS0lqdkvbqAM7dfZgI0HjE/WL4lfCf/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "241201 rn2 2156",
              "title": "241201 rn2 2156",
              "src": "/static/cfda799c86e121eacc15c7657e10c1cc/6a068/241201_rn2_2156.jpg",
              "srcSet": ["/static/cfda799c86e121eacc15c7657e10c1cc/f93b5/241201_rn2_2156.jpg 300w", "/static/cfda799c86e121eacc15c7657e10c1cc/b4294/241201_rn2_2156.jpg 600w", "/static/cfda799c86e121eacc15c7657e10c1cc/6a068/241201_rn2_2156.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
      `}<figcaption parentName="div">{`
        `}<strong parentName="figcaption">{`EACH PERSON `}</strong>{`who professed a decision for Jesus Christ
        in Vietnam and Laos was given Biblical discipleship materials.
      `}</figcaption>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     
    `}<strong parentName="div">{`Will you pray for these believers in Vietnam and Laos?`}</strong>{` Ask
    God to strengthen them as they attend a local Bible-teaching church. More
    than 300 congregations worked hard to prepare for these Festivals, and now
    committed Christians and pastors will disciple those who made decisions for
    the Lord. Please also join me in praying for continued open doors for the
    Gospel in both of these nations.
  `}</div>{`
  `}<br parentName="div"></br>{`
   `}<br parentName="div"></br>{`
  `}<h4 parentName="div">{`EVANGELISM ACROSS THE GLOBE IN 2025`}</h4>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     Please also pray for Gospel ministry in the new year. In March,
    `}<strong parentName="div">{`
      I’ll be preaching on the African continent for the first time in many
      years.
    `}</strong>{`
    We’ve been invited by churches in Addis Ababa, Ethiopia, to hold an
    evangelistic Festival. This diverse city is one of Africa’s major capitals,
    with more than 3 million residents. Pray now that the Holy Spirit will begin
    working in the hearts of men and women across the region and that He “`}<em parentName="div">{`will grant 
    them repentance, so that they may know the truth`}</em>{`” (2
    Timothy 2:25, NKJV) during the Festival in March.
    `}<strong parentName="div">{`
      Later in the year I will preach in the United Kingdom, Belgium, Spain,
      Argentina, and Cambodia.
    `}</strong>{`
    Will you lift each of these events up to the Lord in prayer?
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     I’m deeply grateful for you and your heart for evangelism. None of
    this would be possible without the commitment, support, and prayers of
    friends like you—and it’s my prayer that God blesses you and your loved ones
    in the coming year.
  `}</div>{`
  `}<p parentName="div">{`May God richly bless you,`}</p>{`
  `}<img parentName="div" {...{
        "className": "signature",
        "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
        "alt": ""
      }}></img>{`
  `}<p parentName="div">{`Franklin Graham`}</p>{`
  `}<p parentName="div">{`President & CEO`}</p>{`
  `}<p parentName="div">{`*Names and photos changed to protect privacy`}</p>{`
  `}<hr parentName="div"></hr>{`
  `}<div parentName="div" {...{
        "className": "give-background",
        "id": "january-give"
      }}>{`
    `}<h4 parentName="div" {...{
          "className": "cta projects"
        }}>{`
      `}<span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE
    `}</h4>{`
    `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
      GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
      GOSPEL.
    `}</h4>{`
    `}<p parentName="div">{`
      `}<span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes
      lives—now and for eternity. When you designate a gift for
      `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate
      ministry opportunities and needs, including the personnel, resources,
      materials, equipment, and support services vital to the work of evangelism
      around the world.
    `}</p>{`
    `}<p parentName="div">{`
      `}<span parentName="p" {...{
            "className": "options"
          }}>{`EVANGELISTIC CRUSADES`}</span>{` | So many people
      are living without the hope that comes from Jesus Christ. When you send a
      gift for `}<strong parentName="p">{`Evangelistic Crusades`}</strong>{`, you give people across
      the globe the chance to hear the Gospel at events like the upcoming
      Franklin Graham Festival in Ethiopia and Will Graham Celebration in
      Arizona.
    `}</p>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      