import React from 'react';

const decisionMagazineDigitalCopy = ({ firstName }) => (
  <div>
    <h2 className="confirmation-dc">
      Good news, {firstName}! Your <em>Decision</em> magazine “Pray for the
      President” is on its way to your inbox.
    </h2>
  </div>
);

export default decisionMagazineDigitalCopy;
