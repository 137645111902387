import React from 'react';

const decisionMagazineHardCopy = ({ firstName }) => (
  <div>
    <h2 className="confirmation-hc">
      Good news, {firstName}! Your <em>Decision</em> magazine “Pray for the
      President” will be delivered to your mailbox soon.
    </h2>
  </div>
);

export default decisionMagazineHardCopy;
